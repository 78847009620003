<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h6 font-weight-regular"> Lavorazione carico carro {{ targaCarro }} </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card color="white">
          <v-card-title class="text-body-1 font-weight-light py-2 my-2 warning">
            GESTIONE CARRO
          </v-card-title>
          <v-card-text>
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col>
                  <v-btn @click="goCarroAssociaDanni(carroConvoglio)" block x-large color="orange lighten-5">
                    Danni
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="goCarroAssociaSigillo(carroConvoglio)" block x-large color="light-blue lighten-5">
                    Sigilli
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="onOpenRifiutaCarroDialog" :disabled="disableBtnRifiutaCarroConvoglio" block x-large :color="colorBtnRifiutaCarroConvoglio">
                    Rifiuta
                  </v-btn>
                  <v-dialog v-model="rifiutaCarroDialog" max-width="500px">
                    <v-card-text style="background-color: #ffffff">
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete label="motivo rifiuto" v-model.number="motivoRifiutoCarro" :items="listMotivazioniRifiuto" item-text="label" item-value="id" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="notaRifiutoCarro" label="note" counter="250" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions style="background-color: #ffffff">
                      <v-spacer></v-spacer>
                      <v-btn text @click="saveCarroConvoglioMotivoRifiuto(carroConvoglio)">
                        Salva
                      </v-btn>
                      <v-btn text :disabled="disabledBtnAnnullaRifiutaCarroConvoglio" @click="rifiutaAnnullaRifiutoCarroConvoglio(carroConvoglio)">
                        Annulla Rifiuto
                      </v-btn>
                      <v-btn text @click="rifiutaCarroDialog = false">
                        Chiudi
                      </v-btn>
                    </v-card-actions>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="utiWagonDepartureList && utiWagonDepartureList.length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-body-1 font-weight-light py-2 my-2 grey lighten-2">
            GESTIONE UTI
          </v-card-title>
          <v-card-text>
            <v-container fill-height fluid>
              <v-row align="center" justify="center" class="py-3 my-0 white" v-for="utiCarro in utiWagonDepartureList" :key="utiCarro.id">
                <v-col>
                  <v-container class="text-h5 font-weight-regular pa-0 ma-0">
                    <v-row class="pa-0 ma-0" justify="center">
                      <v-col cols="12" class="font-weight-black pa-0 ma-0">
                        {{ displayUtiCarro(utiCarro) }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col>
                  <v-btn
                    block
                    x-large
                    @click="caricaAnnullaCaricoUtiCarro(utiCarro)"
                    :disabled="disableUtiCarro(utiCarro, 'CARICA')"
                    :color="colorBtnCarica(utiCarro)"
                    :class="classRemainOnBoard(utiCarro)"
                  >
                    Carica
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block x-large color="orange lighten-5" @click="goUtiAssociaDanni(utiCarro)">
                    Danni
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block x-large color="light-blue lighten-5" @click="goUtiAssociaSigillo(utiCarro)">
                    Sigilli
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="onOpenRifiutaUtiDialog(utiCarro)"
                    :disabled="disableUtiCarro(utiCarro, 'RIFIUTA')"
                    block
                    x-large
                    :color="colorBtnRifiuta(utiCarro)"
                    :class="classRemainOnBoard(utiCarro)"
                  >
                    Rifiuta
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block x-large color="error" @click="rimuoviUtiCarroDaCarroConvoglio(utiCarro)" :disabled="disableUtiCarro(utiCarro, 'RIMUOVI')">
                    <v-icon x-large>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="aggiungiUtiDialog" max-width="70%">
      <v-container style="background-color: #ffffff">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca" single-line hide-details filled dense style="max-width: 800px" />
          </v-col>
        </v-row>
      </v-container>
      <v-container style="background-color: #ffffff" v-if="utiWagonDepartureList && utiWagonDepartureList.length > 0">
        <v-row v-for="utiCarro in utiWagonDepartureList" :key="utiCarro.id">
          <v-col cols="12">
            <v-btn x-large block color="blue lighten-2" @click="aggiungiUtiCarroAConvoglio(utiCarro)">
              <!-- <v-icon x-large color="white">mdi-plus</v-icon> -->
              <span class="text-h5 font-weight-bold">
                {{ utiCarro.visitaUti && utiCarro.visitaUti.uti ? utiCarro.visitaUti.uti.codice : utiCarro.visitaUti.merce }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-divider />
        </v-row>
        <v-row>
          <v-col cols="12" align="end">
            <v-btn large @click="aggiungiUtiDialog = false" color="red lighten-1">
              <span class="text-h6 font-weight-regular"> Chiudi </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container style="background-color: #ffffff" v-else>
        <v-row>
          <v-col cols="12" align="center" class="text-h3 font-weight-bold">
            NO UTI
          </v-col>
        </v-row>
        <v-row>
          <v-divider />
        </v-row>
        <v-row>
          <v-col cols="12" align="end">
            <v-btn large @click="aggiungiUtiDialog = false" color="red lighten-1">
              <span class="text-h6 font-weight-regular"> Chiudi </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="rifiutaUtiDialog" max-width="500px">
      <v-card-text style="background-color: #ffffff">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete label="motivo rifiuto" v-model.number="motivoRifiutoUti" :items="listMotivazioniRifiuto" item-text="label" item-value="id"> </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="notaRifiutoUti" counter="250" label="note"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions style="background-color: #ffffff">
        <v-spacer></v-spacer>
        <v-btn text @click="saveUtiMotivoRifiuto(utiCarroSelected)">
          Salva
        </v-btn>
        <v-btn text :disabled="disabledBtnAnnullaRifiutaUti(utiCarroSelected)" @click="rifiutaAnnullaRifiutoUtiCarro(utiCarroSelected)">Annulla Rifiuto</v-btn>
        <v-btn text @click="rifiutaUtiDialog = false">Chiudi</v-btn>
      </v-card-actions>
    </v-dialog>
    <v-dialog v-model="showGoodVisitLoad" persistent width="600" v-if="showGoodVisitLoad">
      <GoodVisitLoad :unit="utiCarroSelected.visitaUti" v-on:ok="doProcessMerceVaria" v-on:cancel="showGoodVisitLoad = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import GoodVisitLoad from "@/components/rails/GoodVisitLoad";
import railsMixins from "../../components/rails/mixins/railsMixins";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  name: "LavorazioneCaricoCarroConvoglio",
  components: { GoodVisitLoad },
  mixins: [railsMixins, ToastMixin],

  props: ["convoyId", "convoyWagonId", "voyageWagonId"],
  data() {
    return {
      carroConvoglio: {},
      carroViaggio: {},
      aggiungiUtiDialog: false,
      rifiutaUtiDialog: false,
      rifiutaCarroDialog: false,
      notaRifiutoUti: null,
      notaRifiutoCarro: null,
      motivoRifiutoUti: null,
      motivoRifiutoCarro: 0,
      listMotivazioniRifiuto: [],
      logMotivoRifiuto: [],
      utiCarroSelected: null,
      search: null,
      showGoodVisitLoad: false,
    };
  },

  async mounted() {
    try {
      this.carroConvoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "carriConvoglio/" + this.convoyWagonId);

      console.log("lavorazione carro convoglio-this.carroConvoglio: ", this.carroConvoglio);
      this.carroViaggio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "carriViaggio/" + this.voyageWagonId);

      const listDataMotivazioniRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "motivazioniRifiuto/shortList");
      this.listMotivazioniRifiuto = [...listDataMotivazioniRifiuto.data];
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    utiWagonDepartureList() {
      var wdl = [];
      if (this.carroConvoglio.listUti) {
        wdl = this.carroConvoglio.listUti.filter(utiWagon => utiWagon.tipoMovimento === "IMPORT" || utiWagon.remainOnBoardScarico);

        wdl.forEach(w => {
          w.linked = true;
        });
      }
      //carroviaggio
      if (this.carroViaggio) {
        if (this.carroViaggio.listUti && this.carroViaggio.listUti.length > 0) {
          this.carroViaggio.listUti.forEach(utiWagonInExecutionVoyage => {
            if (wdl && wdl.length > 0) {
              var uwdl = wdl.filter(utiWagon => utiWagon.visitaUtiId === utiWagonInExecutionVoyage.visitaUtiId);
              if (!uwdl || uwdl.length === 0) {
                wdl.push(utiWagonInExecutionVoyage);
              }
            } else {
              wdl.push(utiWagonInExecutionVoyage);
            }
          });
        }
      }
      return wdl;
    },
    targaCarro() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro) {
        return this.carroConvoglio.visitaCarro.targa;
      }
    },
    carroConvoglioCaricato() {
      var numCaricati = 0;
      var numDaCaricare = 0;
      if (this.carroConvoglio && this.carroConvoglio.listUti) {
        this.carroConvoglio.listUti.forEach(utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            numDaCaricare++;
            if (utiCarro.stato === "CARICATO") {
              numCaricati++;
            }
          }
        });
      }
      if (numDaCaricare === numCaricati) {
        return true;
      } else {
        return false;
      }
    },
    carroConvoglioRifiutato() {
      var numRifiutati = 0;
      var numDaRifiutare = 0;
      if (this.carroConvoglio && this.carroConvoglio.listUti) {
        this.carroConvoglio.listUti.forEach(utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            numDaRifiutare++;
            if (utiCarro.rifiutato === true) {
              numRifiutati++;
            }
          }
        });
      }
      if (numDaRifiutare === numRifiutati) {
        return true;
      } else {
        return false;
      }
    },
    disableBtnRifiutaCarroConvoglio() {
      if (this.carroConvoglioCaricato === true) {
        return "disabled";
      }
    },
    disabledBtnAnnullaRifiutaCarroConvoglio() {
      if (this.carroConvoglioRifiutato === false) {
        return "disabled";
      }
    },
    disableBtnSigilliCarroConvoglio() {
      if (this.carroConvoglioCancellato === true) {
        return "disabled";
      }
    },
    disableBtnSigilloCarroConvoglio() {
      if (this.carroConvoglio) {
        if (!this.carroConvoglio.visitaCarro.carro.tipoCarro.convenzionale) {
          return "disabled";
        }
      }
    },
    colorBtnRifiutaCarroConvoglio() {
      if (this.carroConvoglioRifiutato === true) {
        return "error";
      } else {
        return "red lighten-5";
      }
    },
  },

  methods: {
    async rimuoviUtiCarroDaCarroConvoglio(utiCarro) {
      console.log("rimuoviUtiCarroDaCarroConvoglio ", utiCarro);
      var carroViaggioId = null;
      if (utiCarro.carroViaggioId) {
        carroViaggioId = utiCarro.carroViaggioId;
      }
      //todo change name rest
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/rimuoviUtiCarroDaCarroConvoglio";
      const reqObj = {
        utiCarroId: utiCarro.id,
        carroViaggioId: carroViaggioId,
      };
      await this.$api.post(fullPath, reqObj);

      utiCarro.carroConvoglioId = null;

      // if (utiCarro.carroViaggioId) {
      var index = -1;
      for (let i = 0; i < this.carroViaggio.listUti.length; i++) {
        if (this.carroViaggio.listUti[i].id === utiCarro.id) {
          index = i;
        }
      }
      if (index > -1) {
        this.carroViaggio.listUti.splice(index, 1);
      }
      // } else {
      var index = -1;
      for (let i = 0; i < this.carroConvoglio.listUti.length; i++) {
        if (this.carroConvoglio.listUti[i].id === utiCarro.id) {
          index = i;
        }
      }
      if (index > -1) {
        this.carroConvoglio.listUti.splice(index, 1);
      }
      // }
    },

    disableUtiCarro(utiCarro, btnType) {
      if (
        (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) ||
        (btnType === "CARICA" && utiCarro.rifiutato === true) ||
        (btnType === "CARICA" && utiCarro.visitaUti && (utiCarro.visitaUti.yardGateIn === null || utiCarro.visitaUti.yardGateIn === "")) ||
        (btnType === "RIFIUTA" && utiCarro.stato === "CARICATO") ||
        (btnType === "RIMUOVI" && utiCarro.stato !== "PIANIFICATO")
        //|| (btnType === "RIMUOVI" && utiCarro.carroViaggio !== null && utiCarro.carroConvoglioId  === null && utiCarro.stato === "PIANIFICATO")
      ) {
        return "disabled";
      }
    },
    disabledBtnAnnullaRifiutaUti(utiCarro) {
      if (utiCarro && utiCarro.rifiutato === false) {
        return "disabled";
      }
    },
    classRemainOnBoard(utiCarro) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        return "uti-rob";
      }
    },
    colorBtnRifiuta(utiCarro) {
      if (utiCarro.rifiutato) {
        return "error";
      } else {
        return "red lighten-5";
      }
    },
    colorBtnCarica(utiCarro) {
      if (utiCarro.stato === "CARICATO") {
        return "primary";
      } else {
        return "blue lighten-5";
      }
    },

    caricaAnnullaCaricoUtiCarro(utiCarro) {
      console.log("--- caricaAnnullaCaricoUtiCarro", utiCarro);
      if (utiCarro.stato === "PIANIFICATO" && utiCarro.visitaUti.countType === 'L') {
        this.utiCarroSelected = utiCarro;
        this.showGoodVisitLoad = true;
      } else {
        this.doCaricaAnnullaCaricoUtiCarro(utiCarro);
      }
    },

    async doProcessMerceVaria() {
      this.showGoodVisitLoad=false;
      this.doCaricaAnnullaCaricoUtiCarro(this.utiCarroSelected);
    },

    async doCaricaAnnullaCaricoUtiCarro(utiCarro) {
      console.log("**** caricaAnnullaCaricoUtiCarro");
      const stato = utiCarro.stato === "PIANIFICATO" ? "CARICATO" : "PIANIFICATO";
      await this.$api.trainData.caricaUtiCarroToCarroConvoglio(this.convoyId, this.convoyWagonId, utiCarro.id, stato, utiCarro.visitaUti.isCompleted);
      utiCarro.stato = stato;
      // if (this.carroConvoglio && this.carroConvoglio.length > 0) {
      //   var index = this.carroConvoglio.listUti.indexOf(utiCarro);
      //   if (index > -1) {
      //     this.carroConvoglio.listUti.splice(index, 1, utiCarro);
      //   }
      // }

      // if (this.carroViaggio && this.carroViaggio.length > 0) {
      //   var indexTwo = this.carroViaggio.listUti.indexOf(utiCarro);
      //   if (indexTwo > -1) {
      //     this.carroViaggio.listUti.splice(index, 1, utiCarro);
      //   }
      // }
    },
    async saveUtiMotivoRifiuto(utiCarro) {
      const createLogRifiutoUtiFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByUtiCarro";
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";

      utiCarro.rifiutato = true;
      const reqObj = {
        utiCarroId: utiCarro.id,
        rifiutato: utiCarro.rifiutato,
      };
      await this.$api.post(fullPath, reqObj);

      const reqCreateRifiutoUtiObj = {
        utiCarroId: utiCarro.id,
        carroConvoglioId: this.carroConvoglio.id,
        motivoRifiutoId: this.motivoRifiutoUti,
        notaRifiuto: this.notaRifiutoUti,
      };
      await this.$api.post(createLogRifiutoUtiFullPath, reqCreateRifiutoUtiObj);

      this.rifiutaUtiDialog = false;
    },
    onOpenAggiungiUtiDialog() {
      this.search = null;
      this.aggiungiUtiDialog = true;
    },
    async onOpenRifiutaCarroDialog() {
      this.motivoRifiutoCarro = null;
      this.notaRifiutoCarro = null;
      if (this.carroConvoglioRifiutato === true) {
        this.logMotivoRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "logRifiuto/carroConvoglio/" + this.convoyWagonId);

        if (this.logMotivoRifiuto.length > 0) {
          this.motivoRifiutoCarro = this.logMotivoRifiuto[0].carroMotivoRifiutoId;
          this.notaRifiutoCarro = this.logMotivoRifiuto[0].notaRifiutoCarro;
        }
      }
      this.rifiutaCarroDialog = true;
    },
    async onOpenRifiutaUtiDialog(utiCarro) {
      this.utiCarroSelected = utiCarro;

      this.motivoRifiutoUti = null;
      this.notaRifiutoUti = null;
      if (utiCarro.rifiutato === true) {
        this.logMotivoRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "logRifiuto/utiCarro/" + utiCarro.id);
        if (this.logMotivoRifiuto.length > 0) {
          this.motivoRifiutoUti = this.logMotivoRifiuto[0].utiMotivoRifiutoId;
          this.notaRifiutoUti = this.logMotivoRifiuto[0].notaRifiutoUti;
        }
      }
      this.rifiutaUtiDialog = true;
    },
    async rifiutaAnnullaRifiutoUtiCarro(utiCarro) {
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";
      if (utiCarro.rifiutato === true) {
        utiCarro.rifiutato = false;
      } else {
        utiCarro.rifiutato = true;
      }
      const reqObj = {
        utiCarroId: utiCarro.id,
        rifiutato: utiCarro.rifiutato,
      };
      await this.$api.post(fullPath, reqObj);
      const deleteMotiviRifiutoFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/deleteByUtiCarro";
      const reqDeleteObj = {
        utiCarroId: this.utiCarroId,
        carroConvoglioId: this.convoyWagonId,
      };
      await this.$api.post(deleteMotiviRifiutoFullPath, reqDeleteObj);
      this.rifiutaUtiDialog = false;
    },
    async saveCarroConvoglioMotivoRifiuto(carroConvoglio) {
      carroConvoglio.listUti.forEach(async utiCarro => await this.updateStatoRifiutatoUti(utiCarro));
      this.createLogRifiutoConvoglio(carroConvoglio);
      carroConvoglio.listUti.forEach(async utiCarro => await this.createLogRifiutoUti(utiCarro));
      this.rifiutaCarroDialog = false;
    },
    async createLogRifiutoConvoglio(carroConvoglio) {
      const createLogRifiutoCarroFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByCarroConvoglio";
      const reqCreateRifiutoObj = {
        carroConvoglioId: carroConvoglio.id,
        motivoRifiutoId: this.motivoRifiutoCarro,
        notaRifiuto: this.notaRifiutoCarro,
      };
      await this.$api.post(createLogRifiutoCarroFullPath, reqCreateRifiutoObj);
    },
    async updateStatoRifiutatoUti(utiCarro) {
      const updateStatoRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateStatoRifiutato";
      if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
        const statoPrec = utiCarro.stato;
        utiCarro.rifiutato = true;
        utiCarro.stato = "CARICATO";
        const reqObj = {
          utiCarroId: utiCarro.id,
          rifiutato: utiCarro.rifiutato,
          stato: utiCarro.stato,
          statoPrec: statoPrec,
        };
        await this.$api.post(updateStatoRifiutatoFullPath, reqObj);
      }
    },
    async createLogRifiutoUti(utiCarro) {
      const createLogRifiutoUtiFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByUtiCarro";
      if (utiCarro.rifiutato === true) {
        const reqCreateRifiutoUtiObj = {
          utiCarroId: utiCarro.id,
          carroConvoglioId: this.carroConvoglio.id,
          motivoRifiutoId: this.motivoRifiutoCarro,
          notaRifiuto: this.notaRifiutoCarro,
        };
        await this.$api.post(createLogRifiutoUtiFullPath, reqCreateRifiutoUtiObj);
      }
    },
    async rifiutaAnnullaRifiutoCarroConvoglio(carroConvoglio) {
      const deleteMotiviRifiutoFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/deleteByCarroConvoglio";
      const updateRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";
      const updateStatoRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateStatoRifiutato";

      const reqDeleteObj = {
        carroConvoglioId: this.convoyWagonId,
      };
      await this.$api.post(deleteMotiviRifiutoFullPath, reqDeleteObj);

      if (this.carroConvoglioRifiutato === true) {
        this.carroConvoglio.listUti.forEach(async utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            utiCarro.rifiutato = false;
            const reqObj = {
              utiCarroId: utiCarro.id,
              rifiutato: utiCarro.rifiutato,
            };
            await this.$api.post(updateRifiutatoFullPath, reqObj);
          }
        });
      } else {
        this.carroConvoglio.listUti.forEach(async utiCarro => {
          if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
            const statoPrec = utiCarro.stato;
            utiCarro.rifiutato = true;
            utiCarro.stato = "CARICATO";
            const reqObj = {
              utiCarroId: utiCarro.id,
              rifiutato: utiCarro.rifiutato,
              stato: utiCarro.stato,
              statoPrec: statoPrec,
            };
            await this.$api.post(updateStatoRifiutatoFullPath, reqObj);
          }
        });
      }
      this.rifiutaCarroDialog = false;
    },
    goCarroAssociaDanni(carroConvoglio) {
      this.$router.push({
        path: "/callTreno/ConvoyAssociaDanni/" + carroConvoglio.id + "/" + " " + "/" + "CARRO",
      });
    },
    goUtiAssociaDanni(utiCarro) {
      this.$router.push({
        path: "/callTreno/ConvoyAssociaDanni/" + this.carroConvoglio.id + "/" + utiCarro.id + "/" + "UTI",
      });
    },
    goCarroAssociaSigillo(carroConvoglio) {
      this.$router.push({
        path: "/callTreno/ConvoyAssociaSigilli/" + carroConvoglio.id + "/" + "CARRO" + "/" + " ",
      });
    },
    goUtiAssociaSigillo(utiCarro) {
      this.$router.push({
        path: "/callTreno/ConvoyAssociaSigilli/" + this.carroConvoglio.id + "/" + "UTI" + 
        "/" + utiCarro.visitaUtiId +"/"+utiCarro.visitaUti.codiceUti
      });
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
